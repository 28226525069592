import './Header.scss';
import logo from './img/logo_ms.svg';

const Header = () => {

  const menuItems = [
    {
      name: 'projects',
      link: '#projects',
    },
    {
      name: 'contact',
      link: '#contact',
    },
  ];

  return (
    <header className="header">
      <div className="header__logo title title--below" data-title="Hello">
        <img src={logo} width="100" height="auto" alt="MS logo" />
      </div>
      <nav className="header__nav">
        <ul>
          {menuItems.map(item => (
            <a key={item.name} href={item.link}>
              <li className="section-title">
                {item.name}
              </li>
            </a>
          ))}
        </ul>
      </nav>
    </header>
  )
}

export default Header;
import './Projects.scss';
import React, { useState, useEffect } from 'react';
import rebatemate_logo from './img/projects/logos/rebatemate_logo.svg';
import infoboard_logo from './img/projects/logos/infoboard_logotype.svg';
import vatseasy_logo from './img/projects/logos/vatseasy_logo.svg';
import neatow_logo from './img/projects/logos/neatow_logo.png';
import keywatcher_logo from './img/projects/logos/keywatcher_logo.svg';
import mikiseman_logo from './img/projects/logos/ms_logo.svg';
import blizzrdr_logo from './img/projects/logos/blizzrdr_logo.png';
import stim_logo from './img/projects/logos/stim_logo.png';
import Modal from './Modal';

const Projects = () => {

  const [project, setProject] = useState({
    name: null,
    logo: null,
    type: null,
    made: null,
    link: null,
    tech: null,
    purpose: null,
    screens: [],
  });
  const [activeScreen, setActiveScreen] = useState(0);
  const [showProject, setShowProject] = useState(false);

  const projects = [
    {
      name: 'rebatemate',
      logo: rebatemate_logo,
      type: 'web app',
      made: '2023',
      tech: 'React',
      purpose: 'Generate submission documents for value added and excise tax returns for diplomats in Slovakia; a continuation of VATsEasy - available to anyone.',
      link: 'https://rm.seman.dev'
    },
    {
      name: 'infoBoard',
      logo: infoboard_logo,
      type: 'web app',
      made: '2018',
      tech: 'Angular, Porteus Kiosk, Slim Framework',
      purpose: 'A touch screen web application to display weather, events, service times, and more information focused on internal customers. The application has its own CMS.',
      screens: [
        'infoboard_1.jpg'
      ]
    },
    {
      name: 'VATsEasy',
      logo: vatseasy_logo,
      type: 'web & desktop app',
      made: '2017',
      tech: 'Angular, Electron, PDFMake',
      purpose: 'A web & desktop application to help internal customers submit local VAT and excise tax forms. Calculates required data and prints out all necessary documents required for submission.',
      screens: [
        'vatseasy_1.jpg', 'vatseasy_2.jpg',
      ]
    },
    {
      name: 'neat.OW',
      logo: neatow_logo,
      type: 'hybrid mobile app',
      made: '2016',
      tech: 'Ionic Angular, Dropbox',
      purpose: 'A hybrid mobile companion app for Blizzard\'s Overwatch game. Focused on providing game information - heroes, maps, news.',
      screens: [
        'neatow_1.jpg', 'neatow_2.jpg', 'neatow_3.jpg', 'neatow_4.jpg',
      ]
    },
    {
      name: 'KeyWatcher',
      logo: keywatcher_logo,
      type: 'web app',
      made: '2021',
      tech: 'Angular, Porteus Kiosk, Slim Framework',
      purpose: 'A touch enabled web application to display internal information. Per request content managers were also able to edit content via touch keyboard without use of peripheral devices.',
      screens: [
        'keywatcher_1.jpg'
      ]
    },
    {
      name: 'mikiseman.sk',
      logo: mikiseman_logo,
      type: 'website',
      made: '2022',
      link: 'https://mikiseman.sk',
      tech: 'Typescript, Bulma',
      purpose: 'My dad\'s personal website to present his hobby - composing sacred music. Most of the copy is his own.',
      screens: [
        'mikiseman_1.jpg', 'mikiseman_2.jpg'
      ],
    },
    {
      name: 'Blizzrdr',
      logo: blizzrdr_logo,
      type: 'hybrid mobile app',
      made: '2015',
      tech: 'Ionic Angular, Dropbox',
      purpose: 'A hybrid mobile news aggregator app listing news articles from popular websites for all Blizzard games.',
      screens: [
        'blizzrdr_1.jpg', 'blizzrdr_2.jpg', 'blizzrdr_3.jpg',
      ]
    },
    {
      name: 'stim.sk',
      logo: stim_logo,
      type: 'website',
      made: '2014',
      tech: 'AngularJS, PHP',
      purpose: 'A personal project website to list StarCraft 2 guides for new players.',
      screens: [
        'stimsk_1.jpg'
      ]
    },
  ];

  const preview = (e, project) => {
    // console.log(e, project);
    setProject(project);
    setShowProject(true);
    // document.addEventListener('keydown', handleKeys);
  }

  const hidePreview = () => {
    // console.log(e);
    document.removeEventListener('keydown', handleKeys);
    setActiveScreen(0);
    setShowProject(false);
  }

  const setScreen = (side) => {
    let screen = activeScreen;
    if (side === 'next') {
      screen++;
    } else if (side === 'prev') {
      screen--;
    }
    setActiveScreen(screen);
  }

  const handleKeys = (e) => {
    // console.log(e.keyCode, activeScreen);
    if (e.keyCode === 27) {
      hidePreview();
    } else if (e.keyCode === 37 && activeScreen !== 0) {
      setScreen('prev');
    }
    else if (e.keyCode === 39 && activeScreen + 1 !== project.screens.length) {
      setScreen('next');
    }
  }

  useEffect(() => {
    if (showProject) {
      document.addEventListener('keydown', handleKeys);
    }
    return () => document.removeEventListener('keydown', handleKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeScreen, showProject]);

  return (
    <>
      <section className="projects" id="projects">
        <h2 className="section-title">Projects</h2>
        <ul className="projects__list">
          {projects.map(project => (
            <li key={project.name} className="project">
              <div className="project__name">
                {project.name} <span>({project.made})</span>
              </div>
              <section className="project__body">
                <div className="project__logo">
                  <img src={project.logo} alt={`${project.name} logo`} loading="lazy" />
                </div>
                <article className="project__about">
                  <div className="project__tech">[ {project.tech} ]</div>
                  <div className="project__purpose">{project.purpose}</div>
                </article>
                {project.link ?
                  <a href={project.link} target="_blank" rel="noreferrer noopener" className="project__preview title" data-title={`${project.link}`}>Visit</a> :
                  <button className="project__preview title" data-title={`${project.screens.length} sample screenshot${project.screens.length > 1 ? 's' : ''}`} type="button" onClick={(e) => preview(e, project)}>Preview</button>
                }
              </section>
            </li>
          ))}
        </ul>
        <p className="projects-other">
          And other projects I am happy to discuss.
        </p>
      </section>
      {showProject && <Modal project={project} handleHide={hidePreview} handleScreens={setScreen} activeScreen={activeScreen} />}
    </>
  );

}

export default Projects;

import './Footer.scss';
import email from './img/email.svg';
import github from './img/github.svg';

const Footer = () => {

  const thisYear = new Date().getFullYear();

  return (
    <section className="contact" id="contact">
      <h2 className="section-title">contact</h2>
      <section>
        <a href="mailto:miki@seman.dev" className="contact__link email">
          <button type="button">
            <span>email me @ miki@seman.dev</span>
          </button>
          <img src={email} alt="Email icon" />
        </a>
        <a href="https://github.com/seman-dev" rel="noopener noreferrer" target="_blank" className="contact__link github">
          <button type="button">
            <span>check out my GitHub</span>
          </button>
          <img src={github} alt="Github logo" />
        </a>
      </section>
      <footer>
        <hr />
        {thisYear && <p>
          Mikuláš "Miki" Seman © 2022-{thisYear}
        </p>}
      </footer>
    </section>
  );
}

export default Footer;
import './About.scss';
import adobe from './img/logo_adobe.svg';
import javascript from './img/logo_javascript.svg';
import php from './img/logo_php.svg';
import { ReactComponent as Slovakia } from './img/svk.svg';

const About = () => {

  const thisYear = new Date().getFullYear();

  const exp = [
    {
      name: 'JavaScript',
      years: `${thisYear - 2015}+`,
      level: 'Advanced',
      logo: javascript,
      libs: [
        'Angular', 'Firebase', 'Ionic', 'jQuery', '{\'learning React\'}',
      ],
    },
    {
      name: 'PHP',
      years: `${thisYear - 2015}+`,
      level: 'Advanced',
      logo: php,
      libs: [
        'Slim', 'Wordpress',
      ],
    },
    {
      name: 'Adobe',
      years: `${thisYear - 2016}+`,
      level: 'Advanced',
      logo: adobe,
      libs: [
        'Illustrator', 'InDesign', 'Photoshop',
      ],
    }
  ];

  return (
    <main>
      <div className="lead">
        <h1 className="lead__hip">
          I am a <span>frontend dev</span> based in
          Bratislava, Slovakia.
        </h1>
        <a href="resume_final_final_final.pdf" rel="noopener noreferrer" target="_blank">
          <button className="lead__cta title" data-title="Open PDF">
            look at my resumé
          </button>
        </a>
        <Slovakia className="lead__svk" />
      </div>
      <div className="exp">
        <ul>
          {exp.map((exp, i) => (
            <li key={exp.name} className={`exp__item subtle-dance ${i === 1 ? 'subtle-variation' : ''} ${i === 2 ? 'subtle-variation-2' : ''}`}>
              <img src={exp.logo} alt={`${exp.name} logo`} width="80" height="80" />
              <div className="exp__item__info">
                <h2>{exp.name}</h2>
                <p>
                  {exp.years} years
                </p>
                <p>
                  {exp.level} efficiency
                </p>
                <p>
                  [ {exp.libs.map((lib, i) => {
                    return <span key={i}>{lib}{i + 1 === exp.libs.length ? '' : ', '}</span>
                  })} ]
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </main>
  );
}

export default About;

import './Modal.scss';

const Modal = (props) => {

  return (
    <div className="modal">
      {/* BUTTON CLOSE */}
      <button className="modal__button modal__button--close" onClick={props.handleHide}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" /></svg>
      </button>

      {/* BUTTON LEFT */}
      <button className={`modal__button modal__button--left ${props.project.screens.length > 1 && props.activeScreen !== 0 ? '' : 'hidden'}`} onClick={(e) => { props.handleScreens('prev') }}>
        <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" /></svg>
      </button>

      {/* BUTTON RIGHT */}
      <button className={`modal__button modal__button--right ${props.project.screens.length > 1 && props.activeScreen !== props.project.screens.length - 1 ? '' : 'hidden'}`} onClick={(e) => { props.handleScreens('next') }}>
        <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" /></svg>
      </button>

      <header className="modal__title">
        <h2>
          {props.project.name}
        </h2>
      </header>
      <img src={"/img/projects/screens/" + props.project.screens[props.activeScreen]} alt={`${props.project.name} project screenshot`} loading="lazy" />
      <footer className="modal__button modal__button--count">
        {props.activeScreen + 1} / {props.project.screens.length}
      </footer>
    </div>
  );

}

export default Modal;